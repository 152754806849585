import React from "react";

//Components
import SubSectionTitle from "../../common/SubSectionTitle";

export default function WhatIsIt() {
	return (
		<div className="">
			<SubSectionTitle subTitle="Qu'est-ce c'est"></SubSectionTitle>

			<p className="text-center">
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
				eiusmod tempor incididunt ut labore et dolore magna aliqua.
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
				eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem
				ipsum dolor sit amet, consectetur adipiscing elit, sed do
				eiusmod tempor incididunt ut labore et dolore magna aliqua.
			</p>
		</div>
	);
}
